import type { FC } from "react";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { Badge } from "@/components/ui/badge";
import { ResourcesPanel } from "./resources";
import { OutputsPanel } from "./outputs/outputs";

export const PlaygroundHome: FC = () => {
  return (
    <ResizablePanelGroup
      direction="horizontal"
      className="h-full w-full"
    >
      <ResizablePanel
        className="rounded-2xl p-2 m-2 border-accent border-2 flex flex-col"
        defaultSize={20}
      >
        <Badge className="self-start">Resources</Badge>
        <ResourcesPanel />
      </ResizablePanel>
      <ResizableHandle withHandle />
      <ResizablePanel className="rounded-2xl p-2 m-2 border-accent border-2 overflow-y-auto flex flex-col">
        <Badge className="self-start">Outputs</Badge>
        <OutputsPanel />
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};