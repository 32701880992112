import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { createRoute, Link, redirect, type AnyRoute } from "@tanstack/react-router"
import { PlusCircleIcon } from "lucide-react"
import { ofetch } from "ofetch"
import type { Playground } from "shared/data/playground"



export const homeRoute = <TParentRoute extends AnyRoute = AnyRoute>(parent: TParentRoute)=> {
    const r = createRoute({
    beforeLoad: async ({context}) => {
    const user = context.user;
    if (!user){
        document.location.href = "/api/auth/signin"
        return null
    }
    if (!user.isAllowed){
        return redirect({
        to: "/not-allowed",
        throw: true,
        })
    }
    return true;
    },
    getParentRoute: () => parent,
    loader: async () => {
        const {playgrounds}: {playgrounds: Playground[]} = await ofetch('/api/playgrounds')
        return {playgrounds}
    },
    path: '/',
    component: ()=> {
        const {playgrounds} = r.useLoaderData();
        return <PlaygroundsHome playgrounds={playgrounds}/>
    },
    })
  return r;
}
export const PlaygroundsHome: React.FC<{playgrounds: Playground[]}> = ({playgrounds}) => {
    return (
        <div className="w-screen p-4">
        <h1 className="text-3xl my-4">Playgrounds</h1>
        <div className="grid grid-cols-4 gap-4">
            <div>
                <Link to="/new-playground" className="cursor-pointer">
                <Card className="p-4 border-2 border-white">
                    <CardHeader className="h-24">
                        <CardTitle>Create new playground</CardTitle>
                    </CardHeader>
                    <CardContent className="flex flex-col items-end text-al">
                        <PlusCircleIcon size={52}/>
                        
                    </CardContent>
                </Card>
                </Link>
            </div>
        {
            playgrounds.map(p=> <Link to="/p/$playgroundId" key={p.id} params={{playgroundId: p.id}}>  <Card className="cursor-pointer p-4" key={p.id}>
                <CardHeader className="h-32">
                    <CardTitle className="leading-8">{p.name}</CardTitle>
                </CardHeader>
                <CardContent>
                    
                </CardContent>
                <CardFooter>
                <CardDescription>
                    Created at {new Date(p.createdAt ?? new Date().getTime()).toLocaleString()}
                </CardDescription>
                </CardFooter>
                </Card>
                </Link>)
        }
        </div>
        </div>
    );
}