import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "@/components/ui/card";
import { ScrollBar, ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select"
import { match } from "ts-pattern";
import { atom, useAtomValue, useAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { ChevronsUpDown, TrashIcon, PlusIcon } from "lucide-react";
import { useCallback, useState, useTransition, useMemo } from "react";
import type { UIResource } from "shared/data/resource";
import type { ClientToolDef } from "shared/tool";
import { P } from "ts-pattern";
import { j_playgroundId, useSelectOutputTab } from "../state";
import { j_baseResources, j_resourceById, j_localResources, j_remoteResources } from "../state";
import { Badge } from "@/components/ui/badge";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { j_runningProcesses, useResourceApi } from "../hooks";
import jsonSchemaToZod from "json-schema-to-zod";

const ResourceFromTool = ({
  tool,
  onSubmit,
}: {
  tool: ClientToolDef;
  onSubmit: (input: unknown) => void;
}) => {
  const schema = tool.args;

  const form = useForm({
    resolver: zodResolver(schema),
  });
  console.log(schema)

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit, (err)=>console.log(err))} className="space-y-8">
        {Object.entries(schema.shape).map(([key, value]) => {
          const fieldType = value.isOptional() ? value._def.innerType : value;
          return (
            <FormField
              key={key}
              control={form.control}
              name={key}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{value.description || key}</FormLabel>
                  <FormControl>
                    {match(fieldType).with(P.instanceOf(z.ZodBoolean), ()=> 
                      <Input type="checkbox" {...field} onChange={x=> field.onChange(x.target.checked)} />
                    )
                    .with(P.instanceOf(z.ZodNumber), ()=>
                      <>
                      <Input type="number" {...field} onChange={x=> field.onChange(x.target.valueAsNumber)} />
                      </>
                    )
                    .otherwise(()=> 
                      <>
                      <Input {...field} />
                      </>
                    )}
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          );
        })}
        <Button type="submit">Generate</Button>
      </form>
    </Form>
  );
};

const ResourceStatus:React.FC<{className?:string, status: UIResource["status"]}> = ({status, className}) => {
  if (!status) return null;
  const badgeColor = match(status)
  .with("draft", ()=>"bg-white" as const)
  .with("init", ()=>"bg-orange-400" as const)
  .with("generating", ()=>"bg-orange-400 animate-pulse" as const)
  .with("error", ()=>"bg-red-300" as const)
  .with("done", ()=>"bg-green-300" as const)
  .with("paused", ()=>"bg-yellow-300" as const)
  .with("suspended", ()=>"bg-yellow-300" as const)
  .exhaustive()
  return <Badge className={`${className} ${badgeColor}`}>{status}</Badge>
}

const j_tools = atom(
    async (get) =>
      await fetch("/api/tools")
        .then((x) => x.json())
        .then((x) => {
          const tools = x.tools as ClientToolDef & { args: string}[]
          return tools.map(x=> {
            return {
              ...x,
              args: new Function("z", `return (${jsonSchemaToZod((JSON.parse(x.args)), { module: "none"})})`)(z)
            } as ClientToolDef
          })
        })
  );
  
  export const ResourcePanelChildResources:React.FC<{resourceId:string}> = ({resourceId}) => {
    const allResources = useAtomValue(j_baseResources)
    const childResources = allResources
    .filter(x=>
      (function isDescendant(child: UIResource, root: string) {
        if (child.parentResource == null) return false
        if (child.parentResource === root) return true
        const parentResource = allResources.find(x=>x.id === child.parentResource)
        if (!parentResource) return false
        return false;
        //return isDescendant(parentResource, root)
    })(x, resourceId)
  )
  
  const selectOutputTab = useSelectOutputTab()
  
    return <>
      <ul className="flex flex-col gap-2">
      {childResources.map(x=> {
        return <li  key={x.id} className="rounded border-2 p-2 flex flex-col gap-2">
          <div onClick={()=> selectOutputTab(x.id)} className="flex flex-row cursor-pointer">
          <div>
          <div className="text-[0.8rem] text-orange-400">
          {x.generator?.tool}
          </div>
          <div className="text-sm font-bold text-ellipsis overflow-x-hidden text-pretty whitespace-nowrap max-w-[250px]">
          {x.friendlyName}
          </div>
         
          </div>
          <div className="ms-auto">
          <ResourceStatus className="text-[0.6rem]" status={x.status} />
          
          </div>
          </div>
          <Collapsible>
            <CollapsibleContent>
            <div className="flex flex-col gap-2">
            <div className="flex flex-row">
            <ul>
            {Object.entries(x.generator?.args ?? {}).map(([key,value])=> 
              <li key={key} className="text-[0.7rem] text-ellipsis overflow-hidden max-h-[2rem]">
                <span className="font-bold">{key}</span>: { match(value)
                            .with(
                              { $$resourceId: P.string },
                              ({ $$resourceId }) => (
                                <span className="text-yellow-600 font-black">
                                  #{$$resourceId}
                                </span>
                              )
                            )
                            .otherwise(() => `${value}`)}
              </li>
            )}
            </ul>
            <div className="ms-auto text-[0.7rem]">#{x.id}</div>
            </div>
            <ResourcePanelChildResources resourceId={x.id} />
          </div>
            </CollapsibleContent>
            <CollapsibleTrigger asChild>
              <div className="bg-gray-900 p-1">
              <ChevronsUpDown className="h-4 w-4 mx-auto" />
              </div>
            </CollapsibleTrigger>
          </Collapsible>
          </li>
      })}
      </ul>
    </>
  }
  
  export const ResourcePanel: React.FC<{ resourceId: string }> = ({ resourceId }) => {
    const [resource, setResource] = useAtom(j_resourceById(resourceId));
    const availableTools = useAtomValue(j_tools);
    const [toolName, setToolName] = useState<string>(
      resource.generator?.tool ?? "campaign-manager" );
    const tool = availableTools.find((x) => x.name === toolName);
    /*
    if (!tool) {
      throw new Error(`Tool not found ${toolName}`);
    }*/

    const [manualEdit, setManualEdit] = useState(false);
    const isEditing = resource.status === "draft" || manualEdit;
    const [, start] = useTransition();

    const abortController = useAtomValue(j_runningProcesses(resourceId));
    const canStop = abortController !== undefined && resource.status === "generating" || resource.status === "init";
  
    const onDelete = useAtomCallback(
      useCallback(async (get, set, resourceId: string) => {
        set(j_localResources, (g) => g.filter((x) => x.id !== resourceId));
        const res = await fetch(
          `/api/playgrounds/${playgroundId}/resources/${resourceId}`,
          {
            method: "DELETE",
          }
        );
        await set(j_remoteResources);
      }, [])
    );

    const resourceApi = useResourceApi();
  
    const selectOutputTab = useSelectOutputTab();

    const onSubmit = useCallback(async (s: any, regenerate = false)=> {
      await resourceApi.create({
        resourceId: resourceId,
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        tool: tool!,
        toolArgs: s,
        regenerate: regenerate
      })
    }, [resourceApi, resourceId, tool])

    const onResume = useCallback(async ()=>{
      await resourceApi.resume(resourceId)
    }, [resourceApi, resourceId])

  
    const playgroundId = useAtomValue(j_playgroundId);
    
    return (
      <Card>
        <CardHeader onClick={()=> {
          if (resource.status !== "draft") {
            selectOutputTab(resourceId)
          }
        }} className={`relative ${!isEditing ? "cursor-pointer" : ""}`}>
          {!isEditing ? <div className="text-sm text-orange-400">{toolName}</div> : null}
          <CardTitle className="text-lg">{resource.friendlyName ?? "New"}</CardTitle>
          <ResourceStatus className="absolute right-4 top-4" status={resource.status} />
        </CardHeader>
        <CardContent className="flex flex-col gap-2">
          {isEditing ? (
            <>
              <Select value={toolName} onValueChange={setToolName}>
                <SelectTrigger>
                  <SelectValue>{toolName}</SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {availableTools.map((x) => (
                    <SelectItem key={x.name} value={x.name}>
                      {x.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <ResourceFromTool
                key={tool?.name}
                tool={tool}
                onSubmit={x=> (onSubmit(x, false))}
              />
            </>
          ) : 
            (
              <>
              <Collapsible>
                <CollapsibleTrigger>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="flex flex-row gap-2"
                  >
                    Args
                    <ChevronsUpDown className="h-4 w-4" />
                  </Button>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  {Object.entries(resource.generator?.args ?? {}).map(
                    ([name, value], i) => (
                      <div className="text-xs p-4" key={name}>
                        <div className="font-black">{name}</div>
                        <div>
                          {match(value)
                            .with(
                              { $$resourceId: P.string },
                              ({ $$resourceId }) => (
                                <span className="text-yellow-600 font-black">
                                  #{$$resourceId}
                                </span>
                              )
                            )
                            .otherwise(() => `${value}`)}
                        </div>
                      </div>
                    )
                  )}
                </CollapsibleContent>
              </Collapsible>
            {resource.output?.childResources?.length ? 
            <Collapsible defaultOpen={false}  className="flex flex-col gap-2">
            <CollapsibleTrigger>
                <Button
                  variant="ghost"
                  size="sm"
                  className="flex flex-row gap-2"
                >
                  Using
                  <ChevronsUpDown className="h-4 w-4" />
                </Button>
              </CollapsibleTrigger>
              <CollapsibleContent>
                <ResourcePanelChildResources resourceId={resource.id} />
              </CollapsibleContent>
            </Collapsible>
            : null}

            <Button size="sm" className="self-start" variant="secondary" onClick={(()=> {
              onSubmit(resource.generator?.args, true)
            })}>Regenerate</Button>

            {canStop ? <Button size="sm" className="self-start" variant="secondary" onClick={(()=> {
              abortController?.abort()
            })}>Stop</Button> : null}

            {(resource.status === "paused" || (resource.status === "generating" && !canStop)) ? <Button size="sm" className="self-start" variant="secondary" onClick={onResume}>Resume</Button> : null}
            </>
          )}
        </CardContent>
        <CardFooter className="relative">
          <div className="absolute right-4 bottom-2 flex flex-row items-center text-xs">
            {resource.id}
            <Button
              size="icon"
              variant="ghost"
              onClick={() => {
                start(() => {
                  onDelete(resourceId);
                });
              }}
            >
              <TrashIcon />
            </Button>
          </div>
        </CardFooter>
      </Card>
    );
  };
  
  /*
  const j_resourceTree= atom((get)=> {
    const base = get(j_baseResources)
  
  })
  */
  
  export const ResourcesPanel = () => {
    const resources = useAtomValue(
      useMemo(() => atom((get) => get(j_baseResources).filter(x=>!x.parentResource).map((x) => x.id)), [])
    );
    const addResource = useAtomCallback(
      useCallback((get, set) => {
        const id =
          Math.random().toString(36).substring(2, 5) +
          Math.random().toString(36).substring(2, 5);
        set(j_localResources, (g) => [
          ...g,
          {
            id,
            friendlyName: "new",
            playground: get(j_playgroundId),
            status: "draft",
          },
        ]);
      }, [])
    );
  
    return (
      <>
      <ScrollArea>
        <ScrollBar orientation="vertical" />
        <div className="flex flex-col gap-4 overflow-y-auto">
          {resources.map((x) => (
            <ResourcePanel key={x} resourceId={x} />
          ))}
          <Button
            variant="ghost"
            className="self-center justify-self-center content-center justify-center"
            onClick={addResource}
          >
            <PlusIcon />
          </Button>
        </div>
        </ScrollArea>
      </>
    );
  };
  