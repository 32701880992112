import { ResourceFlow } from "@/components/Resource-Flow";
import { Button } from "@/components/ui/button";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { match, P } from "ts-pattern";
import { useAtomValue, useAtom } from "jotai";
import { ExternalLink, Link, SquareArrowOutUpRight, XCircleIcon } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  j_selectedOutputTab,
  useSelectOutputTab,
  useCloseOutputTab,
  j_availableOutputs,
  j_suspendedResources,
  j_resourceById,
  j_playgroundId,
} from "../state";
import { WireframePanel } from "./wireframe";
import { MarkdownPanel } from "./markdown";
import { SuspendResourcePanel, SuspendWidget } from "./suspend";
import { WebsitePanel } from "./website";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { Badge } from "@/components/ui/badge";
import { ResourcesPanel } from "../resources";
import type { UIResource } from "shared/data/resource";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import {
  getPanelElement,
  type ImperativePanelHandle,
} from "react-resizable-panels";
import { ImagePanel } from "./image";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { useCopyToClipboard } from "react-use"
import { useGetRootParentResource, useResourceApi } from "../hooks";
import { ErrorBoundary } from "react-error-boundary";
import { MarkdownWithOriginal } from "@/components/Markdown";

export const OutputsPanel = () => {
  const availableResources = useAtomValue(j_availableOutputs);
  const selectOutputTab = useSelectOutputTab();
  const closeOutputTab = useCloseOutputTab();

  const [tab, setTab] = useAtom(j_selectedOutputTab);
  const ref = useRef(null);
  const suspendedResources = useAtomValue(j_suspendedResources);
  const visibleResources = availableResources.concat(
    suspendedResources.filter(
      (x) => !availableResources.some((y) => y.id === x.id)
    )
  );
  const selectedTab =
    tab && visibleResources.some((x) => x.id === tab) ? tab : "flow";

  if (visibleResources.length === 0) {
    return null;
  }
  return (
    <Tabs
      ref={ref}
      className="flex flex-col overflow-hidden flex-1"
      value={selectedTab}
      onValueChange={(x) => {
        setTab(x);
      }}
    >
      <TabsList className="border-b-2 border-b-accent w-full bg-transparent justify-start overflow-x-auto min-h-9 gap-2 py-0 p-0">
        <div className="flex flex-row items-center border-t-2 border-l-2 border-r-2 px-1 border-accent">
          <TabsTrigger value="flow">Flow</TabsTrigger>
        </div>
        {visibleResources.map((x) => (
          <div
            key={x.id}
            className="flex flex-row items-center border-t-2 border-l-2 border-r-2 px-1 border-accent"
          >
            <TabsTrigger key={x.id} value={x.id}>
              {x.friendlyName ?? x.id}
            </TabsTrigger>
            <Button
              size="icon"
              variant="ghost"
              className="h-4 w-4"
              onClick={(e) => {
                closeOutputTab(x.id);
                e.stopPropagation();
              }}
            >
              <XCircleIcon />
            </Button>
          </div>
        ))}
      </TabsList>

      <div className="p-2 overflow-y-auto flex flex-col flex-1">
      <ErrorBoundary key="flow" fallbackRender={(e)=> {
              return <div>Failed to rendered flow tree</div>
            }}>
        <TabsContent key="flow" asChild value="flow">
          
          <ResourceFlow onResourceClicked={(id) => selectOutputTab(id)} />
        </TabsContent>
        </ErrorBoundary>
        {visibleResources.map((x) => {
          return (
            <ErrorBoundary key={x.id} fallbackRender={(e)=> {
              return <div>Failed to rendered resource #{x.id} {`${e.error}`}</div>
            }}>
            <TabsContent asChild key={x.id} value={x.id}>
              <ResourceTab resource={x} isActive={x.id === tab} />
            </TabsContent>
            </ErrorBoundary>
          );
        })}
      </div>
    </Tabs>
  );
};

const ResourceArg = ({id}:{id: string}) => {
  const resource = useAtomValue(j_resourceById(id));
  const selectTab = useSelectOutputTab();
  // biome-ignore lint/a11y/useValidAnchor: <explanation>
  return <a className="text-orange-400 underline cursor-pointer" onClick={()=>{
    selectTab(id)
  }}>#{resource.friendlyName ?? resource.id}</a>

}

const PreviewArgs = ({ args }: { args: Record<string, unknown> }) => {
  
  return <div className="grid grid-cols-[1fr,2fr] gap-4">
    {Object.entries(args).map(([k, v]) => (
      <>
        <div key={k}>{k}</div>
        <div key={`${k}-value`}>{match(v)
        .with({$$resourceId: P.string}, (x)=> <ResourceArg id={x.$$resourceId} />)
        .with(P.string, (x)=> x)
        .with(P.number, (x)=> x)
        .with(P.boolean, (x)=> x)
        .otherwise(() => JSON.stringify(v))
        }</div>
      </>
    ))}
  </div>
}

const ResourceTab = ({
  resource,
  isActive,
}: {
  resource: UIResource;
  isActive: boolean;
}) => {
  const tracePanel = useRef<ImperativePanelHandle>(null);
  const playground = useAtomValue(j_playgroundId);
  const artifact = match(resource.generator?.outputType)
    .with("wireframe", () => <WireframePanel resourceRef={resource.id} />)
    .with("document", () => <MarkdownPanel resourceRef={resource.id} />)
    .with("website", () => <WebsitePanel resourceRef={resource.id} />)
    .with("image", () => <ImagePanel resourceRef={resource.id} />)
    .with("suspender", ()=> <SuspendResourcePanel resource={resource} />)
    .otherwise(() => <MarkdownPanel resourceRef={resource.id} />);

  const badgeColor = match(resource.status)
    .with("draft", () => "bg-white" as const)
    .with("init", () => "bg-orange-400" as const)
    .with("generating", () => "bg-orange-400 animate-pulse" as const)
    .with("error", () => "bg-red-300" as const)
    .with("done", () => "bg-green-300" as const)
    .with("paused", () => "bg-yellow-300" as const)
    .with("suspended", () => "bg-yellow-300" as const)
    .exhaustive();

  const resourceRawUrl = useMemo(()=> new URL(`/api/playgrounds/${playground}/resources/${resource.id}/raw`, document.location.href).href, [resource.id, playground])
  const [clipboardState, copyToClipboard] = useCopyToClipboard();
  const rootParent = useGetRootParentResource(resource.id);
  const resourceApi = useResourceApi();

  return (
    <div className="flex flex-col flex-1 relative overflow-hidden">
      <div className="flex flex-col  py-2 px-4 gap-1 rounded-lg">
        <div className="text-md text-orange-400">
          {resource.generator?.tool}
        </div>

        <div className="flex  flex-row  items-center  gap-2">
          <h2 className="text-3xl ">{resource.friendlyName}</h2>
          <div>
            <Badge className={`text-sm ${badgeColor}`}>{resource.status}</Badge>
          </div>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <div className="text-sm">#{resource.id}</div>
          <HoverCard>
          <HoverCardTrigger asChild>
          <Button size="sm" variant="link">Args</Button>
          </HoverCardTrigger>
          <HoverCardContent className="w-fit max-w-[60vw]">
            <PreviewArgs args={resource.generator?.args ?? {}} />
          </HoverCardContent>
          </HoverCard>
          <Button onClick={()=> {
            window.open(resourceRawUrl, "_blank")
          }} size="sm" variant="outline">
          <ExternalLink className="mr-2 h-4 w-4"  /> Open Raw 
          </Button>
          <Button size="sm" variant="outline" onClick={()=>{
            copyToClipboard(resourceRawUrl)
          }}>
          <Link className="mr-2 h-4 w-4" /> {clipboardState.value ? "Copied!" : "Copy URL"}
          </Button>
          {(rootParent?.id && rootParent.id !== resource.id) ? <Button size={"sm"} variant="outline" onClick={async ()=>{
            await resourceApi.regenerateFromCheckpoint(rootParent?.id, resource.id)
          }} >Regenerate from here</Button> : null} 
        </div>
      </div>

      <ResizablePanelGroup
        direction="horizontal"
        className="flex flex-1 relative overflow-auto"
      >
        <ResizablePanel
          ref={tracePanel}
          className="rounded-2xl p-2 m-2 border-accent border-2 flex flex-col gap-4"
          defaultSize={20}
        >
          <ScrollArea>
            <ScrollBar orientation="vertical" />
            <ErrorBoundary fallbackRender={(e)=> {
              return <div>Failed to render steps: {`${e.error}`}</div>
            }} >
            <StepsPanel resource={resource} />
            </ErrorBoundary>
          </ScrollArea>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel className="rounded-2xl p-2 m-2 border-accent border-2 overflow-y-auto flex flex-col">
          {artifact}
          {resource.status === "suspended" && isActive ? (
            <SuspendWidget resource={resource} />
          ) : null}
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
};

const ResourceCard = ({ id }: { id: string }) => {
  const resource = useAtomValue(j_resourceById(id));
  const badgeColor = match(resource.status)
    .with("draft", () => "bg-white" as const)
    .with("init", () => "bg-orange-400" as const)
    .with("generating", () => "bg-orange-400 animate-pulse" as const)
    .with("error", () => "bg-red-300" as const)
    .with("done", () => "bg-green-300" as const)
    .with("paused", () => "bg-yellow-300" as const)
    .with("suspended", () => "bg-yellow-300" as const)
    .exhaustive();
  const selectOutputTab = useSelectOutputTab();
  const resourceRawUrl = useMemo(()=> new URL(`/api/playgrounds/${resource.playground}/resources/${resource.id}/raw`, document.location.href).href, [resource.id, resource.playground])
  const [clipboardState, copyToClipboard] = useCopyToClipboard();
  const rootParent = useGetRootParentResource(resource.id);
  const resourceApi = useResourceApi();
  const latestAiTrace = resource.trace?.findLast(x=>x.type === "progress")

  return (
    <HoverCard>
    <HoverCardTrigger>
    <div className="p-2 border-2 border-accent rounded-lg">
      <div className="flex flex-row items-center cursor-pointer">
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <div onClick={() => selectOutputTab(id)} className="flex-grow">
          {resource.friendlyName ?? resource.id}
        </div>
        <Badge className={badgeColor}>{resource.status}</Badge>
      </div>
    </div>
    </HoverCardTrigger>
    <HoverCardContent side="right" 
                align="end" 
                className="w-auto p-2 flex flex-col gap-2"
                sideOffset={10}>
      <div className="w-[250px] overflow-auto">
        <div className="rounded-md overflow-auto h-40 border-2 p-2 m-2">
          {match(resource.status)
          .with("generating", () => latestAiTrace?.data ?? "Waiting for progress...")
          .with("done", () => 
            match(resource.generator?.outputType)
            .with("wireframe", () => "Wireframe is ready")
            .with("document", () => <MarkdownWithOriginal className="">{resource.output?.data}</MarkdownWithOriginal>)
            .with("website", () =>
              <div style={{transformOrigin: "0 0", transform: "scale(0.2)", position:"relative"}}>
              <iframe style={{ position: "absolute", top:0, left: 0, width: 1100, height: 550 }} src={resourceRawUrl} />
              </div>
               )
            .with("image", () => <img src={resource.output?.data} alt="output" />)
            .otherwise(() => resource.output?.data ?? "Ready" )
          )
          .with("error", () => resource.output?.data ?? "Error")
          .with("suspended", () => "Waiting for user input...")
          .otherwise(() => "")
          }
        {}
        </div>
      </div> 
      <div className="w-auto p-2 flex flex-row gap-2">
      <Button size="sm" variant="outline" onClick={()=>{
            copyToClipboard(resourceRawUrl)
          }}>
          <Link className="mr-2 h-4 w-4" /> {clipboardState.value ? "Copied!" : "Copy URL"}
          </Button>
          {rootParent?.id ? <Button size={"sm"} variant="outline" onClick={async ()=>{
            await resourceApi.regenerateFromCheckpoint(rootParent?.id, resource.id)
          }} >Regenerate from here</Button> : null} 
       </div>
    </HoverCardContent>
    </HoverCard>
  );
};

const TriggeredBy = ({ resource }: { resource: string }) => {
  const res = useAtomValue(j_resourceById(resource));
  const selectOutputTab = useSelectOutputTab();
  return (
    <>
      <Badge className="bg-slate-800 text-white p-1 rounded-md self-start">
        Triggered by
      </Badge>
      <Button
        className="whitespace-break-spaces text-left self-start"
        onClick={() => selectOutputTab(res.id)}
        size="sm"
        variant="link"
      >
        {res.friendlyName}
      </Button>
    </>
  );
};

const StepsPanel = ({ resource }: { resource: UIResource }) => {
  //const aiTraces = [...(resource.status === "generating" ? (resource.output.data?? "").matchAll(/\<ai-trace\>(.*?)(\<\/ai-trace\>|$)/gs) : [])].map(x=>x[1])
  //...aiTraces.map(x=> ({type: "progress", data: x})),
  const childResources =
    resource.status !== "draft" && resource.status !== "init"
      ? resource.output?.childResources ?? []
      : [];
  const allTraces = [
    ...(resource.trace ?? []).filter(
      (x) => x.type !== "data" && x.type !== "create-subresource"
    ),
    ...childResources.map((x) => ({ type: "child", data: x })),
  ];
  // brain emoji: 🧠
  return (
    <div className="flex flex-col gap-2">
      <>
        {resource.parentResource ? (
          <TriggeredBy resource={resource.parentResource} />
        ) : null}
        <Badge className="bg-slate-800 text-white p-1 rounded-md self-start">
          Steps
        </Badge>
        {allTraces.map((x, i) =>
          match(x)
          
            .with({ type: "progress" }, (x) => (
              <div key={i} className="p-2 border-2 border-gray-800 rounded-lg">
                {x.data}
              </div>
            ))
            .with({ type: "child" }, (x) => (
              <ErrorBoundary key={i} fallbackRender={(e)=> {
                return <div className="p-2 border-2 border-red-800 rounded-lg">Error displaying: #{x.data} ({`${e.error}`})</div>
              }}>
              <ResourceCard key={i} id={x.data} />
              </ErrorBoundary>
            ))
            
            //.with({type: "log"}, (x) => x.data)
            //.with({type: "suspend"}, (x) => "Suspended")
            .otherwise(() => null)
        )}
      </>
    </div>
  );
};
