import { MarkdownWithOriginal } from "@/components/Markdown";
import { useAtomValue } from "jotai";
import { j_resourceById } from "../state";
import { getResourceOutput } from "@/lib/resource-utils";

export const  MarkdownPanel: React.FC<{ resourceRef: string }> = ({ resourceRef }) => {
  const resource = useAtomValue(j_resourceById(resourceRef));
  const output = getResourceOutput(resource);
  return <MarkdownWithOriginal className="[text-wrap:balance] w-full">
  { output ?? "" }
</MarkdownWithOriginal>
}