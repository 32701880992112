import { ReactFlow, Background, type Node, type Edge, useNodesState, useEdgesState, useReactFlow, ReactFlowProvider, Handle, Position } from '@xyflow/react';
import type React from 'react';
import type { UIResource } from 'shared/data/resource';
import Dagre from '@dagrejs/dagre';
import { useEffect, useMemo } from 'react';
import '@xyflow/react/dist/style.css';
import { match } from 'ts-pattern';
import { useAtomValue } from 'jotai';
import { j_baseResources } from '@/pages/playground/state';
import { indexBy } from 'remeda'

const getLayoutedElements = (nodes: Omit<Node, "position">[], edges: Edge[]) => {
    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
    g.setGraph({ rankdir: 'LR' });
  

    for (const edge of edges) {
      g.setEdge(edge.source, edge.target);
    }
    
    for (const node of nodes) {
      g.setNode(node.id, {
        ...node,
        width: node.width,
        height: node.height,
      });
    }
  
    Dagre.layout(g);
  
    return {
      nodes: nodes.map((node) => {
        const position = g.node(node.id);
        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        const x = position.x - (node.measured?.width ?? 0) / 2;
        const y = position.y - (node.measured?.height ?? 0) / 2;
  
        return { ...node, position: { x, y } } as Node;
      }),
      edges,
    };
  };


type NarrowType<T extends Record<string, unknown>, UKey extends keyof T, UValue extends T[UKey]> =
    {
    [P in keyof T]: P extends UKey ? Exclude<T[P], UValue> : T[P];
    }

type ActiveResource = NarrowType<UIResource, "status", "draft">

export const ResourceFlow: React.FC<{onResourceClicked :(resourceId: string)=> void}> = ({onResourceClicked}) => {
    const allResources = useAtomValue(j_baseResources)
    const rootResources = allResources.filter(x=>x.parentResource == null).map(x=>x.id)
    const resourceIndex = useMemo(()=> indexBy(allResources, x=>x.id), [allResources])
    const expandedResources = rootResources.flatMap(function collect(id:string): ActiveResource[]{
      const r = resourceIndex[id]
      if (!r || r.status === "draft"){
        return []
      }
      if (r.status === "done") {
        return [r]
      }
      if (r.status === "generating" || r.status === "error") {
        return [r, ...(r.output?.childResources ?? []).flatMap(x=> collect(x))]
      }
      return [r]
    })
    return <ReactFlowProvider>
              <ResourceFlowInner resources={expandedResources} onResourceClicked={onResourceClicked} />
      </ReactFlowProvider>
}

export function ResourceNode({ data, height, width, isConnectable }: {data: {label: string, resource: ActiveResource}, isConnectable: boolean, width?: number, height?: number}) {
  const classes = match(data.resource.status)
  .with("init", ()=>"border-orange-400" as const)
  .with("generating", ()=>"border-orange-400 animate-pulse" as const)
  .with("error", ()=>"border-green-300" as const)
  .with("done", ()=>"border-green-300" as const)
  .with("suspended", ()=>"border-yellow-300" as const)
  .with("paused", ()=>"border-yellow-300" as const)
  .exhaustive()
  
  
  return (
    <div style={{width, height}} className={`bg-black rounded-sm shadow-md p-2 border-2 ${classes}`}>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        id='call'
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="dep"
        isConnectable={isConnectable}
      />
      <div>
      <div>
        <div className='text-[0.4rem] text-orange-400'>{data.resource.generator?.tool}</div>
        <div className='text-[0.6rem]'>{data.label}</div>
      </div>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        id="call"
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position={Position.Top}
        id="dep"
        isConnectable={isConnectable}
      />
    </div>
  );
}


const ResourceFlowInner: React.FC<{resources: ActiveResource[], onResourceClicked :(resourceId: string)=> void}> = ({resources, onResourceClicked}) => {
  const { fitView } = useReactFlow();

  const [nodes, setNodes, onNodesChange] = useNodesState([] as Node[]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([] as Edge[]);

    const resourceNodes = useMemo(()=>resources.map((r)=>({
        id: r.id,
        width: 150,
        height: 60,
        type: 'resourceNode',
        data: {label: r.friendlyName, resource: r}
    })), [resources])

    const toolCallsEdges = useMemo(()=> resources.flatMap((r)=> r.parentResource  ? [{
        id: `${r.parentResource}-${r.id}`,
        sourceHandle: 'call',
        targetHandle: 'call',
        source: r.parentResource,
        target: r.id
    }] : []), [resources])

    const depEdges = useMemo(()=> resources.flatMap((r)=> Object.entries(r.generator?.args ?? {}).filter(([_,x])=> typeof x === "object" &&  "$$resourceId" in x).map(([_,x])=> ({
        id: `${r.id}-${x.$$resourceId}`,
        sourceHandle: 'dep',
        targetHandle: 'dep',
        source: x.$$resourceId,
        target: r.id,
    })) ?? []), [resources])

    

    
    useEffect(()=> {
      const { nodes, edges } = getLayoutedElements(resourceNodes, [...depEdges, ...toolCallsEdges]);
      setNodes(nodes)
      setEdges(edges)
      window.requestAnimationFrame(() => {
        fitView();
      });
    }, [resourceNodes, toolCallsEdges, depEdges, setNodes, setEdges, fitView]);

    return (
        <div style={{ height: '100%' }}>
          <ReactFlow colorMode='dark' fitView nodes={nodes} edges={edges} onNodeClick={(e, n)=>onResourceClicked(n.id)} 
          nodeTypes={{ resourceNode: ResourceNode }} >
            <Background />
          </ReactFlow>
        </div>
      );

}