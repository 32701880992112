import { MarkdownWithOriginal } from "@/components/Markdown";
import { useAtomValue } from "jotai";
import { j_resourceById } from "../state";
import { getResourceOutput } from "@/lib/resource-utils";

export const  ImagePanel: React.FC<{ resourceRef: string }> = ({ resourceRef }) => {
  const resource = useAtomValue(j_resourceById(resourceRef));
  const imageUrl = getResourceOutput(resource)!;
  return <picture>
  <source srcSet={imageUrl}  />
  <img src="https://placehold.co/600x400" alt="Fallback" />
</picture>
}