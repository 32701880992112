import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs"
import { useAtomValue } from "jotai";
import { useMemo, useRef, useCallback, useEffect } from "react";
import { j_resourceById } from "../state";
import { getResourceOutput } from "@/lib/resource-utils";

const removeAITraces = (input: string) => {
  return input.replace(/<ai-trace>.*?(<\/ai-trace>|$)/gs, "")
}

export const WebsitePanel: React.FC<{ resourceRef: string }> = ({ resourceRef }) => {
  const source = useAtomValue(j_resourceById(resourceRef));
  const generatedCode = useMemo(
    () =>
      getResourceOutput(source)?.replace(/^\`\`\`html(.*)\`\`\`$/s, "$1" ?? "") ?? "",
    [source]
  );
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const docIsOpen = useRef(false);
  const currentDocPosition = useRef(0);
  const previous = useRef<string | null>(generatedCode);
  useEffect(()=> {
    if (previous.current === generatedCode){
      return;
    }
    if (previous.current && generatedCode && !generatedCode.startsWith(previous.current)){
      
      iframeRef.current?.contentDocument?.close();
      iframeRef.current?.contentDocument?.open();
      iframeRef.current?.contentDocument?.write(generatedCode);
      docIsOpen.current = true;
      currentDocPosition.current = generatedCode.length;
    }
    previous.current = generatedCode;
  }, [generatedCode])

  const syncContent = useCallback(() => {
    if (!iframeRef.current || !generatedCode) {
      return;
    }
    if (currentDocPosition.current < generatedCode.length) {
      const doc = iframeRef.current.contentDocument;
      if (!docIsOpen.current) {
        doc?.open();
        docIsOpen.current = true;
      }
      doc?.write(generatedCode.slice(currentDocPosition.current));
      currentDocPosition.current = generatedCode.length;
    }
    if (
      source.status === "done" &&
      docIsOpen.current &&
      currentDocPosition.current === generatedCode?.length
    ) {
      docIsOpen.current = false;
      iframeRef.current?.contentDocument?.close();
    }
  }, [iframeRef.current, generatedCode, source.status]);
  useEffect(() => {
    syncContent();
  }, [syncContent]);
  

  return (
    <Tabs
      className="flex flex-col h-full overflow-hidden"
      defaultValue="preview"
    >
      <TabsList className="justify-start">
        <TabsTrigger value="preview">Preview</TabsTrigger>
        <TabsTrigger value="source">Source</TabsTrigger>
      </TabsList>
      <TabsContent asChild value="preview">
        <iframe
          title="website"
          ref={(e) => {
            if (e) {
              iframeRef.current = e;
            }
            if (e && !e["synced"]) {
              currentDocPosition.current = 0;
              e["synced"] = true;
              syncContent();
            }
          }}
          className="flex flex-1 overflow-auto bg-white h-full"
        />
      </TabsContent>
      <TabsContent value="source" asChild className="flex flex-1 overflow-auto">
        <pre className="text-xs">{generatedCode}</pre>
      </TabsContent>
    </Tabs>
  );
};
