import type { UIResource } from "shared/data/resource";
import type { LogTrace } from "shared/data/resource-stream";


export function getResourceOutput(r: UIResource) {
    if (r.status === "done" || r.status === "error") {
        return r.output?.data;
    }
    if (r.status === "generating" || r.status === "paused" || r.status === "suspended") {
        //@ts-ignore
        const dataTraces:LogTrace[] = (r.trace  ?? []).filter((x) => x.type === "data");
        let data = "";
        for (const trace of dataTraces) {
            if (trace.flush){
              data = "";   
            }
            data += trace.data;
        }   
        return data;
    }
    return null;
}