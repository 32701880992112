import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { PlaygroundHome } from "./pages/playground";
import { j_playgroundId, j_remoteResources, j_remoteResources_sync } from "./pages/playground/state";
import "./globals.css"
import { createStore, Provider } from "jotai"
import paintWorklet from "./assets/rough-painter.js?url"
import { createRootRoute, createRoute, Outlet, RouterProvider, createRouter, redirect, Link, useLoaderData } from "@tanstack/react-router";
import { SessionProvider, useSession } from "@hono/auth-js/react"

import 'jotai-devtools/styles.css'
import { Card, CardContent, CardHeader, CardTitle } from "./components/ui/card";
import { PostHogProvider, usePostHog} from 'posthog-js/react'
import { adminRoute } from "./pages/admin";
import type { User } from "@auth/core/types";
import { homeRoute } from "./pages/home";
import { use } from "react"
import { Avatar, AvatarFallback, AvatarImage } from "./components/ui/avatar";
import { Skeleton } from "./components/ui/skeleton";
CSS.paintWorklet.addModule(paintWorklet);

const rootRoute = createRootRoute({
  component: () => (
    <>
      <Outlet />
    </>
  ),
})

const notAllowedRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/not-allowed',
  component: () => 
    <div className="flex flex-col w-full my-40">
    <Card className="mx-auto my-auto">
      <CardHeader>
        <CardTitle>Livecycle is still in closed beta</CardTitle>
      </CardHeader>
      <CardContent>
          <div className="flex flex-col gap-4">
            <div>
          You've been added to our waitlist, if you can't wait, you can <a href="mailto:support@livecycle.io">reach us</a>.
          </div>
          </div>

      </CardContent>
    </Card>
    </div>,
})

const layoutRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: 'layout',
  component: () => {
    const user = useSession().data?.user
    
    return <>
      <div className="flex flex-col fixed top-0 left-0 right-0 bottom-0">
        <div className="flex flex-row items-center justify-between p-4 bg-gray-900 text-white">
          <div>
            <Link to="/" className="text-white">
            <h1 className="text-2xl">Livecycle AI</h1>
            </Link>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <div className="flex flex-col items-end">
              <div>{user?.name}</div>
              <div>
            <a href="/api/auth/signout" className="text-white">Sign out</a>
            </div>
            </div>
            <Avatar >
            <AvatarImage src={user?.image ?? ""} />
            </Avatar>
          </div>
        </div>
      <div className="flex-1 overflow-auto">
      <Outlet />
      </div>
      </div>
    </>
  },
})

const newPlaygroundRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/new-playground',
  beforeLoad: async ({context}) => {
    const user = context.user;
    if (!user){
      document.location.href = "/api/auth/signin"
      return null
    }
    if (!user.isAllowed){
      return redirect({
        to: "/not-allowed",
        throw: true,
      })
    }
   return true;
  },
  loader: async () => {
    const playgroundRes = await fetch("/api/playgrounds", {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        name: "main"
      }),
    }) //.then(x=>x.json())
    if (!playgroundRes.ok){
      throw new Error("Failed to create playground");
    }
    const {id} = await playgroundRes.json()
    return redirect({
      to: `/p/${id}`,
      throw: true,
    })
  },
})

const playgroundRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: '/p/$playgroundId',
  loader: async ({params}) => {
    const store = createStore()
    const id = params.playgroundId
    store.set(j_playgroundId, id)
    await store.get(j_remoteResources);
    return {store}
  },
  pendingComponent: () => <div className="flex w-full h-full flex-col p-20">
  <Skeleton className="flex-1 w-full h-full" />
</div>,
  component: function Playground() {
    const {store} = playgroundRoute.useLoaderData()
    return <Provider store={store}>
      <PlaygroundHome />
      </Provider>
  },
})

const routeTree = rootRoute.addChildren([layoutRoute, homeRoute(layoutRoute), newPlaygroundRoute, playgroundRoute, notAllowedRoute, adminRoute(layoutRoute)])

const router = createRouter({ routeTree, context: {user: undefined! as User | undefined } })

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

const UserAnalyticsProvider = ({children}: {children: React.ReactNode}) => {
  const posthog = usePostHog();
  const session = useSession();
  const userSession = session.data?.user 
  useEffect(()=> {
    if (!userSession?.email){
      return;
    }
    posthog.identify(userSession.email, {
      email: userSession.email,
      name: userSession.name,
    })
  }, [userSession?.email])
  
  return children
}

const App = () => {
  const auth = useSession();
  if (auth.status === "loading"){
    return;
  }
  return  <PostHogProvider 
  apiKey="phc_6diyp44WAiUJmihs6aqexBb8jPp5TivvPVu02hXlpcC"
  options={{
    api_host: "https://us.i.posthog.com",
    person_profiles: 'identified_only' 
  }}>
    <UserAnalyticsProvider>
<RouterProvider router={router} context={{user: auth.data?.user}} />
</UserAnalyticsProvider>
</PostHogProvider>
}
ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <Provider>
      <React.Suspense fallback={<div>Loading...</div>}>
      <SessionProvider>
       <App />
      </SessionProvider>
      </React.Suspense>
      </Provider>
    </React.StrictMode>,
  )